.patient-search-container {
  position: relative;
  width: fit-content;
  min-width: 100px;
  max-width: 200px;
  z-index: 1000;
}

.search-input-container {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #444;
  border-radius: 6px;
  font-size: 14px;
  background: #2a2a2a;
  color: white;
  transition: all 0.2s ease;
}

.search-input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.search-button {
  padding: 12px 24px;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.search-button:hover {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
}

.search-button:disabled {
  background: #444;
  cursor: not-allowed;
  transform: none;
}

.patient-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: #2a2a2a;
  border: 1px solid #444;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
}

.patient-option {
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid #444;
  transition: all 0.2s ease;
}

.patient-option:last-child {
  border-bottom: none;
}

.patient-option:hover {
  background: linear-gradient(135deg, rgba(74, 144, 226, 0.1), rgba(53, 122, 189, 0.1));
  transform: translateX(4px);
}

.patient-name {
  display: block;
  color: #fff;
  font-weight: 500;
  margin-bottom: 4px;
}

.patient-cpf {
  display: block;
  font-size: 12px;
  color: #888;
}

.no-results {
  padding: 16px;
  text-align: center;
  color: #888;
  background: #222;
  border-radius: 6px;
  margin-top: 8px;
}

/* Custom scrollbar for the dropdown */
.patient-dropdown::-webkit-scrollbar {
  width: 8px;
}

.patient-dropdown::-webkit-scrollbar-track {
  background: #222;
  border-radius: 4px;
}

.patient-dropdown::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

.patient-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.new-patient-link {
  display: block;
  margin-top: 8px;
  color: #4CAF50;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}

.new-patient-link:hover {
  text-decoration: underline;
}

.new-patient-option {
  text-decoration: none;
}

.new-patient-option .patient-name {
  color: #4CAF50;
} 