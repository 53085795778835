.estoque-container {
    width: 100%;
    height: 100vh;
    padding: 2rem;
    background: #1a1a1a;
    overflow-y: auto;
    box-sizing: border-box;
}

.table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
}

.table-actions button {
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.3s ease;
}

.table-actions button.success {
    background: #2ecc71;
}

.table-actions button.danger {
    background: #e74c3c;
}

.table-actions button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.estoque-tabs {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tab-button {
    padding: 1rem 2rem;
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
    position: relative;
}

.tab-button.active {
    color: #4a90e2;
}

.tab-button.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #4a90e2;
}

.tab-button:hover {
    color: #4a90e2;
}

table {
    width: 100%;
    border-collapse: collapse;
    background: #2a2a2a;
    border-radius: 12px;
    overflow: hidden;
}

th, td {
    padding: 1rem;
    text-align: left;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

th {
    background: #222;
    font-weight: 600;
    color: #4a90e2;
}

tr:hover {
    background: rgba(74, 144, 226, 0.1);
    transition: background 0.2s ease;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-container {
    background: #2a2a2a;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid rgba(74, 144, 226, 0.3);
}

.modal-header h2 {
    color: #4a90e2;
    margin: 0;
    font-size: 1.5rem;
}

.close-button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #4a90e2;
}

.primary-button {
    background: #4a90e2;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.primary-button:hover {
    background: #357abd;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: rgba(255, 255, 255, 0.8);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.8rem;
    background: #444;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: white;
    font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #4a90e2;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.modal-actions button {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.modal-actions button.primary {
    background: #4a90e2;
    color: white;
}

.modal-actions button.secondary {
    background: #666;
    color: white;
}

.modal-actions button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .table-actions {
        flex-direction: column;
    }
    
    .table-actions button {
        width: 100%;
    }

    .modal-content {
        width: 95%;
        padding: 1rem;
    }

    .add-item-row {
        flex-wrap: wrap;
    }

    .product-search-wrapper {
        flex: 1 1 100%;
        min-width: 100%;
        margin-bottom: 0.5rem;
    }

    .quantity-input,
    .price-input {
        flex: 0 0 auto;
        width: calc(50% - 0.25rem);
    }
}

.loading {
    text-align: center;
    padding: 1rem;
    color: #666;
}

.error-message {
    color: #dc3545;
    padding: 1rem;
    margin: 1rem 0;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
}

.load-more {
    display: block;
    width: 100%;
    padding: 0.75rem;
    margin: 1rem 0;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    color: #495057;
}

.load-more:hover {
    background-color: #e9ecef;
}

.clickable-row {
    cursor: pointer;
}

.clickable-row:hover {
    background-color: rgba(74, 144, 226, 0.1);
    cursor: pointer;
}

.add-item-row {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    width: 100%;
}

.product-search-wrapper {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
}

.quantity-input {
    width: 70px;
    flex: 0 0 70px;
    padding: 0.8rem;
    background: #444;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    height: 42px;
}

.price-input {
    width: 90px;
    flex: 0 0 90px;
    padding: 0.8rem;
    background: #444;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    height: 42px;
}

input[type="number"] {
    padding-right: 0.4rem;
}

.product-search-dropdown {
    min-width: 300px;
    max-height: 300px;
    overflow-y: auto;
}

.items-list {
    margin-top: 20px;
}

.items-list h3 {
    margin-bottom: 10px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.modal-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-actions button.success {
    background-color: #4CAF50;
    color: white;
}

.modal-actions button.success:hover {
    background-color: #45a049;
}

.modal-actions button:not(.success) {
    background-color: #f44336;
    color: white;
}

.modal-actions button:not(.success):hover {
    background-color: #da190b;
}

.form-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.form-row .form-group {
    flex: 1;
    min-width: 200px;
}

.status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.9em;
}

.status-badge.aberta {
    background-color: #e3f2fd;
    color: #1976d2;
}

.status-badge.fechada {
    background-color: #e8f5e9;
    color: #388e3c;
}

.status-filter {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-left: 10px;
    background-color: white;
    cursor: pointer;
}

.status-filter:focus {
    outline: none;
    border-color: #007bff;
}

.search-container {
    display: flex;
    align-items: center;
}

.search-input {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    width: 250px;
    transition: border-color 0.2s;
}

.search-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.product-search-select {
    position: relative;
    z-index: 1100;
}

.file-upload-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
}

.file-upload-label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #333;
}

.file-upload-label svg {
    margin-right: 8px;
}

.file-upload-label:hover {
    color: #007bff;
}

.invoice-details {
    background: #2a2a2a;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 24px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.details-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.details-row {
    display: flex;
    gap: 24px;
}

.details-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.details-group label {
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
}

.details-group span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
}

.details-group .total-value {
    color: #2ecc71;
    font-weight: 600;
    font-size: 1.1rem;
}

.items-section {
    margin-top: 24px;
}

.items-section h3 {
    margin-bottom: 16px;
    color: #4a90e2;
    font-size: 1.1rem;
}

.date-filters {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.date-input-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.date-input-group label {
    font-size: 0.9rem;
    color: #666;
}

.date-input-group input[type="date"] {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
}

.date-input-group select.status-select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    background: #2a2a2a;
    color: white;
    cursor: pointer;
}

.date-input-group select.status-select:focus {
    outline: none;
    border-color: #4a90e2;
}

.filter-button {
    padding: 0.5rem 1rem;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
}

.filter-button:hover {
    background-color: #e0e0e0;
}

.filter-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.dark-button {
    padding: 0.5rem 1rem;
    background-color: #2a2a2a;
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    color: white;
    transition: all 0.3s ease;
}

.dark-button:hover {
    background-color: #3a3a3a;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.dark-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.delete-button {
    background-color: #ff4444;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-button:hover {
    background-color: #cc0000;
}

.supplier-details {
    background-color: #2b2b2b;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
    color: #fff;
}

.supplier-details h3 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #fff;
}

.details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.detail-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.detail-item label {
    color: #888;
    font-size: 0.9em;
}

.detail-item span {
    color: #fff;
    font-size: 1.1em;
} 