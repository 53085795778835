.booking-container {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  background: #1a1a1a;
  overflow-y: auto;
  box-sizing: border-box;
}

.booking-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
  margin-top: 1rem;
}

.booking-section {
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.booking-section h3 {
  color: #4a90e2;
  margin: 0 0 1.5rem 0;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
}

.professional-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.professional-card {
  background: #444;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.professional-card:hover {
  transform: scale(1.05);
  background: #4a90e2;
}

.professional-card.selected {
  background: #4a90e2;
  border: 2px solid white;
}

.procedure-search {
  margin-bottom: 15px;
}

.procedure-search-input {
  width: 100%;
  padding: 12px;
  background: #444;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}

.procedure-search-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.procedure-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: #2a2a2a;
}

.procedure-item {
  padding: 12px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background 0.2s ease;
  color: white;
}

.procedure-item:hover {
  background: #4a90e2;
}

.procedure-item:last-child {
  border-bottom: none;
}

.subprocedure-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: #2a2a2a;
}

.subprocedure-header {
  padding: 12px 15px;
  background: #333;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
}

.subprocedure-header button {
  padding: 8px 12px;
  border: none;
  background: #4a90e2;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subprocedure-header button:hover {
  background: #357abd;
}

.subprocedure-item {
  padding: 12px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background 0.2s ease;
  color: white;
}

.subprocedure-item:hover {
  background: #4a90e2;
}

.selected-procedures {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.selected-procedures h4 {
  color: #4a90e2;
  margin-bottom: 12px;
}

.selected-procedure-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background: #444;
  border-radius: 8px;
  color: white;
}

.procedure-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 10px;
}

.procedure-value-edit, 
.procedure-comment-edit {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.procedure-value-edit label,
.procedure-comment-edit label {
  margin-right: 10px;
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.7);
}

.procedure-value-edit input,
.procedure-comment-edit input {
  flex-grow: 1;
  padding: 12px;
  background: #2a2a2a;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}

.procedure-value-edit input::placeholder,
.procedure-comment-edit input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.selected-procedure-item button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.selected-procedure-item button:hover {
  background-color: #ff1a1a;
}

.time-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.time-card {
  background: #444;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.time-card:hover {
  transform: scale(1.05);
  background: #4a90e2;
}

.time-card.selected {
  background: #4a90e2;
  border: 2px solid white;
}

.date-input {
  width: 100%;
  padding: 12px;
  background: #444;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}

.date-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.book-button {
  width: 100%;
  padding: 0.75rem 1.75rem;
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.book-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.book-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.date-time-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.date-time-container .time-grid {
  margin-top: 10px;
}

.date-time-container .duration-input {
  margin-top: 10px;
}

.time-selection-container {
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: center;
}

.time-column {
  width: 120px;
  background: #444;
  border-radius: 8px;
  padding: 10px;
}

.time-column h4 {
  color: #4a90e2;
  text-align: center;
  margin-bottom: 10px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 5px;
}

.time-scroll {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4a90e2 #444;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-scroll::-webkit-scrollbar {
  width: 8px;
}

.time-scroll::-webkit-scrollbar-track {
  background: #444;
}

.time-scroll::-webkit-scrollbar-thumb {
  background-color: #4a90e2;
  border-radius: 4px;
}

.time-item {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.time-item:hover {
  background: rgba(74, 144, 226, 0.3);
}

.time-item.selected {
  background: #4a90e2;
  color: white;
  font-weight: bold;
}

.success-message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.success-message.success {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4CAF50;
}

.success-message.error {
  background-color: rgba(244, 67, 54, 0.1);
  color: #F44336;
}

.booking-tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tab-button {
  flex: 1;
  padding: 10px 15px;
  background-color: #222222;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
}

.tab-button.active {
  background-color: #2d2d2d;
  border-bottom-color: #4a90e2;
  color: #4a90e2;
}

.tab-button:hover {
  background-color: #2a2a2a;
  color: rgba(255, 255, 255, 0.8);
}

.bookings-list {
  width: 100%;
  overflow-x: auto;
}

.bookings-list table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #1e1e1e;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: #e0e0e0;
}

.bookings-list th, 
.bookings-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #333;
}

.bookings-list th {
  background-color: #2a2a2a;
  font-weight: 600;
  color: #e0e0e0;
}

.bookings-list tr:nth-child(even) {
  background-color: #f9f9f9;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.bookings-search-container {
  width: 100%;
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.bookings-search-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  gap: 15px;
  margin-bottom: 20px;
  align-items: flex-end;
}

.bookings-search-filters > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bookings-search-filters label {
  color: #4a90e2;
  font-weight: 500;
  margin-bottom: 5px;
}

.bookings-search-filters select,
.bookings-search-filters .date-input {
  width: 100%;
  padding: 10px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}

.search-button {
  height: 42px;
  padding: 0 15px;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.search-button:hover {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bookings-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  background-color: #2a2a2a;
}

.bookings-list th, 
.bookings-list td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px;
  text-align: left;
  color: white;
}

.bookings-list th {
  background-color: #333;
  font-weight: 600;
  color: #4a90e2;
}

.bookings-list tr:nth-child(even) {
  background-color: rgba(74, 144, 226, 0.05);
}

.bookings-list tr:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  margin-top: 0;
  color: white;
}

.modal-content p {
  margin: 10px 0;
  color: #e0e0e0;
}

.modal-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.modal-content li {
  padding: 5px 0;
  color: #ff6b6b;
  font-weight: 500;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input[type="datetime-local"],
.form-group input[type="number"],
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.procedure-edit-item {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.procedure-edit-item input[type="text"] {
  flex: 2;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.procedure-edit-item input[type="number"] {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-end;
}

.action-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.action-button.success {
  background-color: #4CAF50;
  color: white;
}

.action-button.success:hover {
  background-color: #45a049;
}

.action-button.danger {
  background-color: #f44336;
  color: white;
}

.action-button.danger:hover {
  background-color: #da190b;
}

.action-button.secondary {
  background-color: #f5f5f5;
  color: #333;
}

.action-button.secondary:hover {
  background-color: #e0e0e0;
}

.modal-content.dark {
  background: linear-gradient(145deg, #222222, #2d2d2d);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.modal-content.dark h2 {
  color: #4a90e2;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}

.modal-content.dark .form-group label {
  color: #4a90e2;
}

.dark-input {
  background: #444 !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.dark-input:focus {
  outline: none;
  border-color: #4a90e2 !important;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.dark-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.modal-content.dark .action-button {
  background: #444;
  color: white;
}

.modal-content.dark .action-button:hover {
  background: #555;
}

.modal-content.dark .action-button.success {
  background: #4CAF50;
}

.modal-content.dark .action-button.success:hover {
  background: #45a049;
}

.modal-content.dark .action-button.danger {
  background: #f44336;
}

.modal-content.dark .action-button.danger:hover {
  background: #da190b;
}

.modal-content.dark .action-button.secondary {
  background: #333;
  color: #fff;
}

.modal-content.dark .action-button.secondary:hover {
  background: #444;
}

.google-calendar-banner {
  background: linear-gradient(145deg, #2d2d2d, #383838);
  padding: 15px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(74, 144, 226, 0.3);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.google-calendar-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, #4a90e2, #357abd, #4a90e2);
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

.google-calendar-banner p {
  margin: 0;
  color: #ffffff;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.google-calendar-button {
  background: linear-gradient(135deg, #4285f4, #357abd);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.google-calendar-button:hover {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}