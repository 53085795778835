.product-search-select {
    position: relative;
    width: 100%;
}

.product-search-input {
    width: 100%;
    padding: 0.8rem;
    background: #444;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: white;
    font-size: 1rem;
}

.product-search-input:focus {
    outline: none;
    border-color: #4a90e2;
}

.product-search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #2a2a2a;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin-top: 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-search-item {
    padding: 0.8rem;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.product-search-item:last-child {
    border-bottom: none;
}

.product-search-item:hover {
    background: rgba(74, 144, 226, 0.1);
}

.product-search-item-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.3rem;
}

.product-search-item-name {
    color: white;
    font-weight: 500;
}

.product-search-item-code {
    color: #4a90e2;
    font-size: 0.9em;
}

.product-search-item-details {
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;
    color: rgba(255, 255, 255, 0.6);
} 