.listing-pr {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.listing-pr h1, .listing-pr p {
    margin-bottom: 20px;
}

.listing-pr select, .listing-pr button, .react-datepicker-wrapper {
    margin: 10px 0;
    padding: 5px 10px;
    border: none;
    background-color: grey;
    color: black;
    cursor: pointer;
}

.listing-pr button {
    padding: 10px 20px;
}

.listing-pr {
    width: 60%;  /* Adjusted to 60% of its parent container's width */
    max-width: 600px;  /* Optional: to ensure it doesn't get too wide on larger screens */
    padding: 20px;
    background-color: rgb(52, 52, 52);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spacing {
    margin-bottom: 20px;
}
/* Rest of the styles remain unchanged */

.flex-container {
    display: flex;
    align-items: center;
}

.remove-button {
    margin-left: 10px;
    background-color: red;
    border: none;
    color: white;
    cursor: pointer;
}