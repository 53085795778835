.supplier-search-container {
  position: relative;
  width: 100%;
}

.search-input-container {
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  font-size: 14px;
  background-color: #2b2b2b;
  color: #fff;
}

.search-input::placeholder {
  color: #888;
}

.loading-indicator {
  padding: 8px;
  text-align: center;
  color: #fff;
  background: #2b2b2b;
  border: 1px solid #444;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.supplier-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #2b2b2b;
  border: 1px solid #444;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.supplier-option {
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #444;
  color: #fff;
}

.supplier-option:last-child {
  border-bottom: none;
}

.supplier-option:hover {
  background-color: #3b3b3b;
}

.supplier-name {
  font-weight: 500;
  color: #fff;
}

.supplier-cnpj {
  color: #888;
  font-size: 0.9em;
}

.no-results {
  padding: 8px;
  text-align: center;
  color: #888;
  background: #2b2b2b;
  border: 1px solid #444;
  border-top: none;
  border-radius: 0 0 4px 4px;
} 