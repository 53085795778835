.historico-container {
  width: 100%;
  height: 100vh;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background: #1a1a1a;
  overflow-y: auto;
  box-sizing: border-box;
}

.historico-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.search-section {
  margin-bottom: 30px;
}

.search-controls {
  background: linear-gradient(145deg, #222222, #2d2d2d);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.search-type {
  margin-bottom: 15px;
  color: #ffffff;
}

.radio-group {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
}

.search-button {
  padding: 0.75rem 1.75rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.search-button:hover {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.history-table th {
  background: rgba(42, 42, 42, 0.6);
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  color: #4a90e2;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.history-table td {
  padding: 1rem;
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.history-table tr:hover {
  background: rgba(74, 144, 226, 0.1);
}

.filter-input {
  width: 100%;
  padding: 0.6rem;
  background: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 8px;
  font-size: 0.9rem;
  margin-top: 5px;
  transition: border-color 0.3s ease;
}

.filter-input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.no-data {
  text-align: center;
  padding: 1.5rem;
  color: #ffffff;
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.tabs-container {
  margin-bottom: 2rem;
}

.tabs-list {
  display: flex;
  gap: 1px;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px;
  border-radius: 12px;
  margin-bottom: 1.5rem;
}

.tab-item {
  flex: 1;
  padding: 0.8rem 1.2rem;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  border-radius: 8px;
  text-align: center;
}

.tab-item.active {
  background: linear-gradient(135deg, #4a90e2, #357abd);
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tab-item:hover:not(.active) {
  color: rgba(255, 255, 255, 0.9);
  background: rgba(74, 144, 226, 0.1);
}

.view-button {
  background: linear-gradient(135deg, #4a90e2, #357abd);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.view-button:hover {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
}

.back-button {
  background: linear-gradient(135deg, #666, #444);
  color: white;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: linear-gradient(135deg, #777, #555);
  transform: translateY(-1px);
}

.content-section {
  background: rgba(42, 42, 42, 0.6);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-section h3 {
  color: #4a90e2;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
}

.anamnesis-text {
  color: #ffffff;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-x: hidden;
  max-width: 100%;
}

.anamnesis-text pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  margin: 0;
  color: #ffffff;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.action-button {
  padding: 0.6rem 1.2rem;
  font-size: 0.95rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #555, #444);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.action-button:hover {
  background: linear-gradient(135deg, #666, #555);
  transform: translateY(-1px);
}

.view-anamnesis-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.view-anamnesis-btn:hover {
  background-color: #45a049;
}

.anamnesis-table-container {
  margin-top: 20px;
  width: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #2a2a2a;
  padding: 2rem;
  border-radius: 12px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: fit-content;
  max-height: 80vh;
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header h2 {
  color: #4a90e2;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.modal-body {
  color: #ffffff;
}

/* Add these spinner styles */
.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 