.pacientes-container {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  background: #1a1a1a;
  overflow-y: auto;
  box-sizing: border-box;
}

.search-section, .add-patient-section {
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.section-title {
  color: #4a90e2;
  margin: 0 0 1.5rem 0;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
}

.patient-info {
  background: rgba(42, 42, 42, 0.6);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

.patient-info p {
  margin: 0.5rem 0;
  display: flex;
  gap: 0.5rem;
}

.patient-info strong {
  color: #4a90e2;
  min-width: 120px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.2rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #4a90e2;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-input {
  padding: 0.8rem;
  background: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

select.form-input {
  cursor: pointer;
}

.submit-button {
  padding: 0.75rem 1.75rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.submit-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.message {
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
  text-align: center;
  font-weight: 500;
}

.success-message {
  background: linear-gradient(135deg, rgba(74, 144, 226, 0.1), rgba(53, 122, 189, 0.1));
  border: 1px solid rgba(74, 144, 226, 0.3);
  color: #4a90e2;
}

.error-message {
  background: linear-gradient(135deg, rgba(255, 68, 68, 0.1), rgba(255, 68, 68, 0.05));
  border: 1px solid rgba(255, 68, 68, 0.3);
  color: #ff4444;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.icon-spin {
  animation: spin 1s linear infinite;
} 