.config-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.config-container h2 {
  color: #333;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.config-section {
  background-color: white;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.config-section h3 {
  color: #444;
  margin-bottom: 10px;
}

.cache-reset-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cache-reset-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cache-reset-button:hover {
  background-color: #d32f2f;
}

.cache-reset-notification {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border-radius: 4px;
  animation: fadeIn 0.5s;
}

.config-description {
  color: #666;
  font-size: 0.9em;
  margin-top: 10px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
} 