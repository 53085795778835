.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.spinner {
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  animation: spin 1s linear infinite;
}

.spinner-small {
  width: 25px;
  height: 25px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
}

.spinner-medium {
  width: 50px;
  height: 50px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
}

.spinner-large {
  width: 75px;
  height: 75px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 