.sidebar {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100vh;
    background-color: #212121;
    padding: 12px 0;
    transition: transform 0.3s ease;
}

.sidebar-toggle {
    display: none;
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1100;
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}

.sidebar-item {
    display: flex;
    align-items: center;
    padding: 10px 24px;
    color: white;
    text-decoration: none;
    transition: background-color 0.2s;
}

.sidebar-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-icon {
    font-size: 20px;
    margin-right: 24px;
}

.signout-button {
    margin-top: auto;
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 15px;
    color: #ff4444;
    display: flex;
    align-items: center;
    text-align: left;
}

.signout-button:hover {
    background-color: rgba(255, 68, 68, 0.1);
}

@media (max-width: 768px) {
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        padding-top: 60px;
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .sidebar-toggle {
        display: block;
    }
}