.transcription-container {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  background: #1a1a1a;
  overflow-y: auto;
  box-sizing: border-box;
}

.audio-controls {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.record-button {
  padding: 0.75rem 1.75rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.record-button:hover {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.record-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recording-indicator {
  color: #ff4444;
  font-weight: 600;
  animation: pulse 1.5s infinite;
  text-shadow: 0 0 10px rgba(255, 68, 68, 0.3);
}

.audio-player {
  flex: 1;
  max-width: 400px;
  border-radius: 8px;
  background: #333;
}

.transcription-area {
  padding: 2rem;
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  min-height: 200px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.content-section {
  background: rgba(42, 42, 42, 0.6);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-section h3 {
  color: #4a90e2;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
}

.transcription-text, .anamnesis-text {
  color: #ffffff;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  width: 100%;
  overflow-x: hidden;
}

.anamnesis-text pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  margin: 0;
  width: 100%;
  color: #ffffff;
  overflow-x: hidden;
}

.edit-container {
  width: 100%;
}

.edit-textarea {
  width: 100%;
  min-height: 200px;
  padding: 1.2rem;
  background: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 8px;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
  resize: vertical;
  transition: border-color 0.3s ease;
}

.edit-textarea:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.action-button {
  padding: 0.6rem 1.2rem;
  font-size: 0.95rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #555, #444);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.action-button:hover {
  background: linear-gradient(135deg, #666, #555);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.action-button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem;
  text-align: center;
}

.loading-container p {
  color: #4a90e2;
  font-size: 1.1rem;
  font-weight: 500;
}

.error-message {
  background: linear-gradient(135deg, rgba(255, 68, 68, 0.1), rgba(255, 68, 68, 0.05));
  border: 1px solid rgba(255, 68, 68, 0.3);
  color: #ff4444;
  padding: 1.2rem;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  box-shadow: 0 4px 6px rgba(255, 68, 68, 0.1);
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

/* Make sure the content area takes full width minus sidebar */
.content {
  flex: 1;
  height: 100vh;
  overflow: hidden;
  background: #141414;
  position: relative;
}

/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.audio-controls-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.record-button {
  flex-shrink: 0;
}

/* Adjust patient search select to fit in the layout */
.patient-search-container {
  flex-grow: 1;
  max-width: 300px;
}

.patient-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1.6;
}

.patient-info p {
  margin: 0;
}

.patient-info strong {
  color: #4a90e2;
  margin-right: 0.5rem;
}